import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Select, Steps, Divider, Checkbox, Descriptions, Space, Form, Spin } from 'antd';
import './App.css';
import { tableauInit, fetchPortfolioList } from './tableauService';

const CheckboxGroup = Checkbox.Group;

const { Option } = Select;
const { Step } = Steps;
declare var tableau: any;

const App = () => {

  const [currentStep, setCurrentStep] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  // step 1 - Credentials
  const [token, setToken] = useState<string>("")

  // step 2 - Portfolio
  const [indeterminate, setIndeterminate] = useState<boolean>(false)
  const [checkAll, setCheckAll] = useState<boolean>(false)
  const [checkedList, setCheckedList] = useState<any[]>([])
  const [floorList, setFloorList] = useState<any[]>([])

  // step 3 - Assets
  const [requestAssets, setRequestAssets] = useState<string[]>([])

  // step 4 - Submit
  const [tableauSubmit, setTableauSubmit] = useState<boolean>(false)


  useEffect(() => {
    
    if (token.length === 0) {
      return;
    }
    setLoading(true);
    fetchPortfolioList(token).then(
      listOfFloor => {
        setFloorList(listOfFloor)
        setLoading(false)
      }
    ).catch(err => {
      console.log(err)
      setFloorList([])
      setLoading(false)
    })
  }, [token])

  useEffect(() => {
    if (requestAssets.length === 0) {
      return;
    }

    tableauInit(token, checkedList, requestAssets);
  }, [tableauSubmit])

  const CredentialsStep = (props: any) => {

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={12} offset={6}>
            <Form
              layout="vertical"
              onFinish={values => {
                setToken((values.token as string).trim())
                setCurrentStep(1)
              }}
              initialValues={{ token }}
            >

              <Form.Item
                label="Auth Token"
                name="token"
                rules={[{ required: true, message: 'Please input your Auth Token' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item style={{ textAlign: "center" }}>
                <Button type="primary" htmlType="submit">
                  Next
              </Button>
              </Form.Item>
            </Form>
          </Col>

        </Row>
      </>
    )
  }

  const PortfolioStep = (props: any) => {

    const onCheckAllChange = e => {
      setCheckedList(e.target.checked ? floorList.map(floor => floor.value) : [])
      setIndeterminate(false)
      setCheckAll(e.target.checked)
    };

    const onSelectedItemChange = checkedIdList => {
      setCheckedList(checkedIdList)
      setIndeterminate(!!checkedIdList.length && checkedIdList.length < floorList.length)
      setCheckAll(checkedIdList.length === floorList.length)
    };

    return (
      <Row gutter={[16, 16]}>
        {loading === false &&
          <><Col span={16} offset={4}>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              Check all
          </Checkbox>
          </Col>
            <Col span={16} offset={4}>
              <div style={{ height: '200px', overflowY: 'auto', padding: '2px 5px', border: '1px solid #ccc' }} >
                <Row>
                  <Col span={24}>
                    <CheckboxGroup
                      defaultValue={checkedList}
                      onChange={onSelectedItemChange}
                    >
                      {floorList.map(floor => <Col span={24} key={floor.value} ><Checkbox value={floor.value}>{floor.label}</Checkbox></Col>)}

                    </CheckboxGroup>
                  </Col>

                </Row>
              </div>
            </Col>
          </>}
        {loading === true && <Col span={16} offset={4}>
          <div style={{ textAlign: "center" }}><Spin /> Loading Portfolio</div>
        </Col>}
        <Col span={12} offset={6} style={{ textAlign: "center" }}>
          <Space>
            <Button size="middle" onClick={() => {
              setCurrentStep(0)
            }}>Back</Button>

            <Button type="primary" size="middle" disabled={checkedList.length === 0} onClick={() => {
              setCurrentStep(2)
            }}>Next</Button>
          </Space>
        </Col>
      </Row>
    )
  }


  const AssetsStep = (props: any) => {
    const assetsList = ["floor", "space", "asset"]
    return (
      <Row>
        <Col span={12} offset={6}>
          <CheckboxGroup
            defaultValue={requestAssets}
            onChange={(selected: any) => {
              let selectedProcessed = selected;

              if (selected.includes("space") && !selected.includes("floor")) {
                selectedProcessed.push("floor")
              }

              if (selected.includes("asset")) {
                if (!selected.includes("floor")) {
                  selectedProcessed.push("floor")
                }
                if (!selected.includes("space")) {
                  selectedProcessed.push("space")
                }
              }
              setRequestAssets(selectedProcessed)
            }}
          >
            {assetsList.map(asset => <Col span={24} key={asset} ><Checkbox value={asset}>{asset}</Checkbox></Col>)}
          </CheckboxGroup>
        </Col>
        <Col span={12} offset={6} style={{ textAlign: "center", marginTop: 10 }}>
          <Space>
            <Button size="middle" onClick={() => {
              setCurrentStep(1)
            }}>Back</Button>

            <Button type="primary" size="middle" disabled={requestAssets.length === 0} onClick={() => {
              setCurrentStep(3)
            }}>Next</Button>
          </Space>
        </Col>
      </Row>
    )
  }


  const SubmitStep = (props: any) => {
    return (
      <Row>
        <Col span={12} offset={6} style={{ textAlign: "center" }}>
          <Descriptions column={1} title="Summary">
            <Descriptions.Item label="Floors Count">{checkedList.length}</Descriptions.Item>
            <Descriptions.Item label="Resources to import">{requestAssets.join(", ")}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={12} offset={6} style={{ textAlign: "center", marginTop: 10 }}>
          <Space>
            <Button size="middle" onClick={() => {
              setCurrentStep(2)
            }}>Back</Button>

            <Button type="primary" disabled={tableauSubmit === true} size="middle" onClick={() => {
              setTableauSubmit(true)
            }}>Submit</Button>
          </Space>

        </Col>
      </Row>
    )
  }

  return (
    <Row justify="center" className="content">
      <Col span={24} sm={{ span: 20 }} xl={{ span: 10 }}>
        <Steps progressDot current={currentStep} size="small">
          <Step title="Credentials" />
          <Step title="Portfolio" />
          <Step title="Resources" />
        </Steps>
        <Divider />
        {currentStep === 0 && <CredentialsStep />}
        {currentStep === 1 && <PortfolioStep />}
        {currentStep === 2 && <AssetsStep />}
        {currentStep === 3 && <SubmitStep />}
      </Col>
    </Row>
  );
}

export default App;
