import Item from "antd/lib/list/Item";
import { Floor, Image, Space, Asset } from "./archilogicInterfaces";
var flatMap = require('array.prototype.flatmap');

declare let tableau: any;

const API_ENDPOINT= "https://api.archilogic.com"


export const setupTableau = () => {
    var myConnector = tableau.makeConnector();

    myConnector.getSchema = schemaCallback => {

        let floorSchema = {
            id: "floor",
            columns: [{
                id: "id",
                dataType: tableau.dataTypeEnum.string
            }, {
                id: "area",
                dataType: tableau.dataTypeEnum.float
            }, {
                id: "name",
                dataType: tableau.dataTypeEnum.string
            }, {
                id: "elevation",
                dataType: tableau.dataTypeEnum.float
            }, {
                id: "2d_image_png",
                dataType: tableau.dataTypeEnum.string
            }
            ]
        };

        let spaceSchema = {
            id: "space",
            columns: [{
                id: "id",
                dataType: tableau.dataTypeEnum.string
            }, {
                id: "area",
                dataType: tableau.dataTypeEnum.float
            }, {
                id: "usage",
                dataType: tableau.dataTypeEnum.string
            }, {
                id: "center_x",
                dataType: tableau.dataTypeEnum.string
            }, {
                id: "center_y",
                dataType: tableau.dataTypeEnum.string
            }, {
                id: "height",
                dataType: tableau.dataTypeEnum.float
            }, {
                id: "program",
                dataType: tableau.dataTypeEnum.string
            }, {
                id: "floor_id",
                dataType: tableau.dataTypeEnum.string
            }]
        }

        let assetSchema = {
            id: "asset",
            columns: [{
                id: "id",
                dataType: tableau.dataTypeEnum.string
            }, {
                id: "name",
                dataType: tableau.dataTypeEnum.string
            }, {
                id: "tags",
                dataType: tableau.dataTypeEnum.string
            }, {
                id: "productId",
                dataType: tableau.dataTypeEnum.string
            }, {
                id: "categories",
                dataType: tableau.dataTypeEnum.string
            }, {
                id: "width",
                dataType: tableau.dataTypeEnum.float
            }, {
                id: "height",
                dataType: tableau.dataTypeEnum.float
            }, {
                id: "length",
                dataType: tableau.dataTypeEnum.float
            }, {
                id: "manufacturer",
                dataType: tableau.dataTypeEnum.string
            }, {
                id: "space_id",
                dataType: tableau.dataTypeEnum.string
            }]
        };

        schemaCallback([floorSchema, spaceSchema, assetSchema]);
    };

    myConnector.getData = (table, doneCallback) => {

        const connectionData = JSON.parse(tableau.connectionData);
        const { authToken, selectedPortfolio, requestAssets } = connectionData;

        if (requestAssets.includes(table.tableInfo.id) === false) {
            doneCallback();
        }

        switch (table.tableInfo.id) {
            case "floor":
                fetchFloors(selectedPortfolio, authToken).then(
                    data => {
                        table.appendRows(data);
                        doneCallback();
                    });
                break;
            case "space":
                fetchSpaces(selectedPortfolio, authToken).then(
                    data => {
                        table.appendRows(data);
                        doneCallback();
                    });
                break;
            case "asset":
                fetchAssets(selectedPortfolio, authToken).then(
                    data => {
                        table.appendRows(data);
                        doneCallback();
                    });
                break;
            default:
                break;
        }


    };

    tableau.registerConnector(myConnector);

}

export const tableauInit = (authToken, selectedPortfolio, requestAssets) => {

    tableau.connectionData = JSON.stringify({ authToken, selectedPortfolio, requestAssets });
    tableau.connectionName = "Archilogic - Web Data Connector";

    tableau.submit();
}

const fetchFloors = async (listFloorsIds: string[], authToken: string) => {

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let floorRequestOptions: any = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    let rawBody = JSON.stringify({ "format": "png" });

    let requestImageOptions: any = {
        method: 'POST',
        headers: myHeaders,
        body: rawBody,
        redirect: 'follow'
    };

    const floorPromises = listFloorsIds.map(floorId => fetch(`${API_ENDPOINT}/v1/floor/${floorId}?token=${authToken}`, floorRequestOptions).then(resp => resp.json()))
    const floorResponses: any[] = await Promise.all(floorPromises);
    const floors: Floor[] = await Promise.all(floorResponses);

    const imagesPromises = listFloorsIds.map(floorId => fetch(`${API_ENDPOINT}/v1/floor/${floorId}/2d-image?token=${authToken}`, requestImageOptions).then(resp => resp.json()))
    const imagesResponses: any[] = await Promise.all(imagesPromises);
    const images: Image[] = await Promise.all(imagesResponses);

    let floorAndImages = [];

    for (let i = 0; i < listFloorsIds.length; i++) {
        const floor = floors[i]
        const image = images[i]

        floorAndImages.push({
            "id": floor.id,
            "area": floor.properties.area,
            "name": floor.properties.name,
            "elevation": floor.properties.elevation,
            "2d_image_png": image.imageUrl
        })
    }

    return floorAndImages

}

const fetchSpaces = async (listFloorsIds: string[], authToken: string) => {

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions: any = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    

    const promises = listFloorsIds.map(floorId => fetch(`${API_ENDPOINT}/v1/space?floorId=${floorId}&token=${authToken}`, requestOptions).then(resp => resp.json()))
    const responses: any[] = await Promise.all(promises);
    const features: any[] = await Promise.all(responses);
    const spaces: Space[] = flatMap(features, (x, i) => x.features );


    return spaces.map(space => {

        return {
            "id": space.id,
            "area": space.properties.area,
            "usage": space.properties.usage,
            "center_x": space.properties.center[0],
            "center_y": space.properties.center[1],
            "height": space.properties.height,
            "program": space.properties.program,
            "floor_id": space.resourceRelations.floors.length > 0 ? space.resourceRelations.floors[0] : null
        }
    }
    );

}


const fetchAssets = async (listFloorsIds: string[], token: string) => {

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions: any = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };


    const promises = listFloorsIds.map(floorId => fetch(`${API_ENDPOINT}/v1/asset?floorId=${floorId}&token=${token}`, requestOptions).then(resp => resp.json()))
    const responses: any[] = await Promise.all(promises);
    const features: any[] = await Promise.all(responses);
    const assets: Asset[] = flatMap(features, (x, i) => x.features );

    return assets.map(asset => {
        return {
            "id": asset.id,
            "name": asset.properties.name,
            "tags": asset.properties.tags.join(", "),
            "productId": asset.properties.productId,
            "categories": asset.properties.categories.join(", "),
            "width": asset.properties.dimensions ? asset.properties.dimensions.width : null,
            "height": asset.properties.dimensions ? asset.properties.dimensions.height : null,
            "length": asset.properties.dimensions ? asset.properties.dimensions.length : null,
            "manufacturer": asset.properties.manufacturer,
            "space_id": asset.resourceRelations.spaces.length > 0 ? asset.resourceRelations.spaces[0] : null
        }
    }
    );

}

export const fetchPortfolioList = async (token: string) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let floorRequestOptions: any = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    const promise = fetch(`${API_ENDPOINT}/v1/floor?token=${token}`, floorRequestOptions).then(resp => resp.json())
    const features: any = await promise

    const floors: Floor[] = features.features

    return floors.map(floor => {
        return {
            "value": floor.id,
            "label": floor.properties.name
        }
    });
}
